import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const TermsPage = () => (
  <Layout>
    <SEO title="EasyBusy: Terms Of Use" />
    <div className={'container'}>
      <div className={'content policies'}>
        <div className={'title'}>
          <h1>Terms &amp; Conditions</h1>
        </div>

        <p className="c5">
          <span>
            Please read these Terms and Conditions (&quot;Terms&quot; or &quot;Terms
            and Conditions&quot;) carefully before using the
          </span>{' '}
          <span>
            <a className="c10" href="https://easybusydo.com">
              easybusydo.com
            </a>
          </span>
          <span className="c4">
            &nbsp;website, the EasyBusy desktop application and the EasyBusy mobile
            application (together, or individually, the &quot;Service&quot;) operated
            by NEW_OWNER. (&quot;EasyBusy&quot;, &quot;us&quot;, &quot;we&quot;,
            or &quot;our&quot;). For purposes of these Terms, &ldquo;you&rdquo; and
            &ldquo;your&rdquo; means you as the user of the Service.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            Your access to and use of the Service is conditioned upon your acceptance
            of and compliance with these Terms. These Terms apply to all visitors,
            users and others who wish to access or use the Service.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            By accessing or using the Service you agree to be bound by these Terms.
            If you disagree with any part of the terms, then you do not have
            permission to access the Service. If you are accessing or using the
            Service on behalf of a business or entity, then your business or entity
            is legally and financially responsible for your access to and use of the
            Service as well as for the use of your account by others affiliated with
            your entity, including any employees, agents or contractors.
          </span>
        </p>
        <h2 className="c1" id="h.5zj3ib6vehvi">
          <span className="c0">1. Content</span>
        </h2>
        <p className="c5">
          <span className="c8">Definitions:</span>
          <span>&nbsp;For purposes of these Terms: (i) &ldquo;</span>
          <span className="c8">Content</span>
          <span>
            &rdquo; means text, graphics, images, music, software, audio, video,
            works of authorship of any kind, and information or other materials that
            are posted, generated, provided or otherwise made available through the
            Service; and (ii) &ldquo;
          </span>
          <span className="c8">User Content</span>
          <span className="c4">
            &rdquo; means any Content that account holders (including you) provide to
            be made available through the Service.
          </span>
        </p>
        <p className="c5">
          <span className="c8">Our Content Ownership:</span>
          <span>&nbsp;</span>
          <span className="c8">
            We do not claim any ownership rights in any User Content
          </span>
          <span className="c4">
            &nbsp;and nothing in these Terms will be deemed to restrict any rights
            that you may have to use and exploit your User Content. Subject to the
            foregoing, EasyBusy and its licensors exclusively own all right, title
            and interest in and to the Service and Content (excluding User Content),
            including all associated intellectual property rights. You acknowledge
            that the Service and Content are protected by copyright, trademark, and
            other laws of the United States and foreign countries. You agree not to
            remove, alter or obscure any copyright, trademark, service mark or other
            proprietary rights notices incorporated in or accompanying the Service or
            Content.
          </span>
        </p>
        <p className="c5">
          <span className="c8">Rights in User Content Granted by You:</span>
          <span className="c4">
            &nbsp;By making any User Content available through the Service, you
            hereby grant to EasyBusy a non-exclusive, transferable, worldwide,
            royalty-free license, with the right to sublicense, to use, host, cache,
            store, reproduce, transmit, distribute and modify (for formatting
            purposes only), your User Content solely in connection with operating and
            providing the Service and Content to you and, depending on the permission
            you grant, to other users. Subject to the foregoing license, as between
            EasyBusy and you, you retain any and all of your rights to your User
            Content.
          </span>
        </p>
        <p className="c5">
          <span className="c8">Your Responsibility for User Content:</span>
          <span className="c4">
            &nbsp;You are solely responsible for all your User Content. You represent
            and warrant that you own all your User Content or you have all rights
            that are necessary to grant us the license rights in your User Content
            under these Terms. You also represent and warrant that neither your User
            Content, nor your use and provision of your User Content to be made
            available through the Service, nor any use of your User Content by
            EasyBusy on or through the Service will infringe, misappropriate or
            violate a third party&rsquo;s intellectual property rights, or rights of
            publicity or privacy, or result in the violation of any applicable law or
            regulation.
          </span>
        </p>
        <p className="c5">
          <span className="c8">Removal of User Content:</span>
          <span className="c4">
            &nbsp;You can remove your User Content by specifically deleting it.
            However, some of your User Content may not be completely removed and
            copies of your User Content may continue to exist on the Service.
            Additional details with respect to the removal and storage of User
            Content are set forth in Section 13 (Termination) below.
          </span>
        </p>
        <p className="c5">
          <span className="c8">Monitoring Content:</span>
          <span className="c4">
            &nbsp;Although we&rsquo;re not obligated to monitor access to or use of
            the Service or Content or to review or edit any Content, we have the
            right to do so for the purpose of operating the Service, to ensure
            compliance with these Terms and to comply with applicable law or other
            legal requirements. We reserve the right, but are not obligated, to
            remove or disable access to any Content, including User Content, at any
            time and without notice, including, but not limited to, if we, at our
            sole discretion, consider any Content to be objectionable or in violation
            of these Terms. We have the right to investigate violations of these
            Terms or conduct that affects the Services. We may also consult and
            cooperate with law enforcement authorities to prosecute users who violate
            the law.
          </span>
        </p>
        <h2 className="c1" id="h.ud8r754xibws">
          <span className="c0">2. Contexts</span>
        </h2>
        <p className="c5">
          <span className="c4">
            Content submitted, posted or modified by users in the Service is
            organized into separated sections referred to as &ldquo;Contexts.&rdquo;
          </span>
        </p>
        <p className="c5">
          <span className="c4">There are two types of Contexts on the Service:</span>
        </p>
        <ul className="c2 lst-kix_ojkrmi1rotv1-0 start">
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              &ldquo;Personal Contexts&rdquo; that can only be accessed by the user
              that created the Contexts. This is the default state for any new
              Context.
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              &ldquo;Shared Contexts&rdquo; that can be accessed by multiple users,
              with one or more users designated as an Owner. Owner explicitly states
              what other users will have access to the context.
            </span>
          </li>
        </ul>
        <p className="c5">
          <span className="c4">
            In our Apps and the Service, you can work across multiple Context at
            once, meaning your single account can access your own Personal Contexts,
            and one or more Shared Contexts.
          </span>
        </p>
        <h2 className="c1" id="h.b0z7j4w9fnoc">
          <span className="c0">3. Subscriptions</span>
        </h2>
        <p className="c5">
          <span className="c4">
            Some parts of the Service are made available on a paid subscription basis
            (&quot;Subscription(s)&quot;).{' '}
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            You will be billed in advance on a recurring and periodic basis
            (&quot;Billing Cycle&quot;). Billing Cycles are set on a monthly or
            yearly basis. Whether you are using monthly or yearly billing, your
            account may be charged on a monthly basis.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            Auto-Renewal: You agree that at the end of each Billing Cycle, your
            Subscription will automatically renew and your payment method for such
            Subscription will automatically be charged at the start of each new
            Subscription period for the fees and taxes applicable to that Billing
            Cycle, under the same conditions as the prior Billing Cycle unless you
            cancel it or EasyBusy cancels it.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            A valid payment method, including credit card, is required to process the
            payment for your Subscription. You must provide EasyBusy with accurate
            and complete billing information including full name, address, and valid
            payment method information. By submitting such payment information, you
            automatically authorize EasyBusy and our processing partners to charge
            all Subscription fees incurred through your account to any such payment
            instruments.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            Should automatic billing fail to occur for any reason, EasyBusy may
            attempt to charge the credit card on file one or more times. Should
            payment continue to fail, the Subscription will be cancelled.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            You may cancel your Subscription either through your online account
            management page or by contacting EasyBusy customer support team at
            support@easybusydo.com.
          </span>
        </p>
        <h2 className="c1" id="h.wvkeg1utpkwp">
          <span className="c0">4. Fees and Fee Changes</span>
        </h2>
        <p className="c5">
          <span>EasyBusy</span>
          <span className="c4">
            , in its sole discretion and at any time, may modify the Subscription
            fees for the Subscriptions. Any Subscription fee change will become
            effective at the end of the then-current Billing Cycle.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            EasyBusy will provide you with a reasonable prior notice of any change in
            Subscription fees to give you an opportunity to terminate your
            Subscription before such change becomes effective.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            Your continued use of the Service after the Subscription fee change comes
            into effect constitutes your agreement to pay the modified Subscription
            fee amount.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            Fees are stated exclusive of any taxes or similar governmental
            assessments of any nature, including, for example, value-added, sales,
            use or withholding taxes, assessable by any jurisdiction (collectively,
            &ldquo;Taxes&rdquo;). You will be responsible for paying all Taxes
            associated with your Subscription, except for those taxes based on our
            net income. Should any payment for the Service be subject to withholding
            tax by any government, you will reimburse us for such withholding tax.
          </span>
        </p>
        <h2 className="c1" id="h.yhm5yz1963fg">
          <span className="c0">5. Refunds</span>
        </h2>
        <p className="c5">
          <span className="c4">
            Payment obligations are non-cancelable, and fees paid are non-refundable
            and there are no credits for partially used Subscription periods.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            Certain refund requests for Subscriptions may be considered by EasyBusy
            on a case-by-case basis and granted in sole discretion of EasyBusy.
          </span>
        </p>
        <h2 className="c1" id="h.thfnmcof6tga">
          <span className="c0">6. Communications from EasyBusy</span>
        </h2>
        <p className="c5">
          <span className="c4">
            By creating an account on our Service, you agree to subscribe to
            newsletters or marketing materials and other promotional information we
            may send. However, you may opt out of receiving any, or all, of these
            marketing communications from us by following the unsubscribe link or
            instructions provided in any email we send. Please note that we may still
            send you transactional or administrative messages related to the Service
            even after you have opted out of receiving marketing communications.
          </span>
        </p>
        <h2 className="c1" id="h.cslsndsza9xs">
          <span className="c0">7. Accounts</span>
        </h2>
        <p className="c5">
          <span className="c4">
            You are responsible for maintaining the confidentiality of your account
            and password, including but not limited to the restriction of access to
            your computer and/or account. You agree to accept responsibility for any
            and all activities or actions that occur under your account and/or
            password, whether your password is with our Service or a third-party
            service. You must notify us immediately upon becoming aware of any breach
            of security or unauthorized use of your account.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            You may not use as a username the name of another person or entity that
            is not lawfully available for use, or a name or trademark that is subject
            to any rights of another person or entity other than you, without
            appropriate authorization. You may not use as a username any name that is
            offensive, vulgar or obscene.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            You may not use the Service if you are 13 years of age or younger. By
            using the Service, you represent and warrant that you are over the age of
            13.
          </span>
        </p>
        <h2 className="c1" id="h.679zo187body">
          <span className="c0">8. Copyright Policy</span>
        </h2>
        <p className="c5">
          <span className="c4">
            We respect the intellectual property rights of others. It is our policy
            to respond to any claim that Content posted on the Service infringes on
            the copyright or other intellectual property rights
            (&quot;Infringement&quot;) of any person or entity. It is our policy, in
            appropriate circumstances and at our discretion, to disable or terminate
            the accounts of users who repeatedly Infringe copyrights or intellectual
            property rights of others.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            If you are a copyright owner, or authorized on behalf of one, and you
            believe that any Content, materials or works uploaded, downloaded or
            appearing on the Service have been copied in a way that constitutes
            copyright infringement, you may submit a notification pursuant to the
            Digital Millennium Copyright Act (DMCA) by providing our copyright agent
            with the following information in writing (see 17 U.S.C 512(c)(3) for
            further detail):
          </span>
        </p>
        <ul className="c2 lst-kix_1vu07rky6g46-0 start">
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              an electronic or physical signature of the person authorized to act on
              behalf of the owner of the copyright&#39;s interest;
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              a description of the copyrighted work that you claim has been
              infringed;
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              identification of the allegedly infringing material on the Service,
              including URL or other specific location on the Service where the
              material that you claim is infringing is located;
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              your address, telephone number, and email address;
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              a statement by you that you have a good faith belief that the disputed
              use is not authorized by the copyright owner, its agent, or the law;
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              a statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the copyright
              owner or authorized to act on the copyright owner&#39;s behalf.
            </span>
          </li>
        </ul>
        <p className="c5">
          <span className="c4">
            Such notices of copyright infringement should be sent to our copyright
            agent via email to support@easybusydo.com.
          </span>
        </p>
        <h2 className="c1" id="h.18kt07ov4od">
          <span className="c0">9. Restrictions on Your Use of the Service</span>
        </h2>
        <p className="c5">
          <span className="c4">
            You may not do any of the following, unless applicable laws or
            regulations prohibit these restrictions, or you have our written
            permission to do so:
          </span>
        </p>
        <ul className="c2 lst-kix_joa0rqnte95r-0 start">
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              post, upload, submit or transmit any User Content that: (i) infringes,
              misappropriates or violates a third party&rsquo;s patent, copyright,
              trademark, trade secret, moral rights or other intellectual property
              rights, or rights of publicity or privacy; (ii) violates, or encourages
              any conduct that would violate, any applicable law or regulation or
              would give rise to civil liability; (iii) is fraudulent, false,
              misleading or deceptive; (iv) is defamatory, obscene, pornographic,
              vulgar or offensive; (v) promotes discrimination, bigotry, racism,
              hatred, harassment or harm against any individual or group; (vi) is
              violent or threatening or promotes violence or actions that are
              threatening to any person or entity; or (vii) promotes illegal or
              harmful activities or substances;
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              duplicate, decompile, reverse engineer, disassemble or decode the
              Service (including any underlying idea or algorithm), or attempt to do
              any of the same;
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              access or use the Service in any manner that could disable, overburden,
              damage, disrupt or impair the Service or interfere with any other
              party&#39;s access to or use of the Service or use any device, software
              or routine that causes the same;
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              attempt to gain unauthorized access to, interfere with, damage or
              disrupt the Service, accounts registered to other users, or the
              computer systems or networks connected to the Service;
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              circumvent, remove, alter, deactivate, degrade or thwart any
              technological measure or content protections of the Service;
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              use any robot, spider, crawlers or other automatic device, process,
              software or queries that intercepts, &ldquo;mines,&rdquo; scrapes or
              otherwise accesses the Service to monitor, extract, copy or collect
              information or data from or through the Service, or engage in any
              manual process to do the same;
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              introduce any viruses, trojan horses, worms, logic bombs or other
              materials that are malicious or technologically harmful;
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              use the Service for illegal, harassing, unethical, or disruptive
              purposes;
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              violate any applicable law or regulation in connection with your access
              to or use of the Service; or
            </span>
          </li>
          <li className="c5 c7 li-bullet-0">
            <span className="c4">
              access or use the Service in any way not expressly permitted by these
              Terms.
            </span>
          </li>
        </ul>
        <h2 className="c1" id="h.5bspeygsye6h">
          <span className="c0">10. Intellectual Property</span>
        </h2>
        <p className="c5">
          <span className="c4">
            The Service and its Content (excluding User Content), features and
            functionality are and will remain the exclusive property of EasyBusy and
            its licensors. The Service is protected by copyright, trademark, and
            other laws of both the United States and foreign countries. Our
            trademarks and trade dress may not be used in connection with any product
            or service without the prior written consent of EasyBusy.
          </span>
        </p>
        <h2 className="c1" id="h.k1e3emi11nk7">
          <span className="c0">11. Feedback</span>
        </h2>
        <p className="c5">
          <span className="c4">
            We welcome feedback, comments and suggestions for improvements to the
            Service (&ldquo;Feedback&rdquo;). You acknowledge and expressly agree
            that any contribution of Feedback does not and will not give or grant you
            any right, title or interest in the Service or in any such Feedback. All
            Feedback becomes the sole and exclusive property of EasyBusy, and
            EasyBusy may use and disclose Feedback in any manner and for any purpose
            whatsoever without further notice or compensation to you and without
            retention by you of any proprietary or other right or claim. You hereby
            assign to EasyBusy any and all right, title and interest (including, but
            not limited to, any patent, copyright, trade secret, trademark, show-how,
            know-how, moral rights and any and all other intellectual property right)
            that you may have in and to any and all Feedback.
          </span>
        </p>
        <h2 className="c1" id="h.hhnqbvb0lfz">
          <span className="c0">12. Links to Other Websites or Services</span>
        </h2>
        <p className="c5">
          <span className="c4">
            Our Service may contain links to third party web sites or services that
            are not owned or controlled by EasyBusy. Our Service may also allow you
            to import or interface with third party applications or services.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            EasyBusy has no control over, and assumes no responsibility for the
            content, privacy policies, or practices of any third-party web sites or
            services. We do not warrant the offerings of any of these
            entities/individuals or their websites.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            You acknowledge and agree that EasyBusy shall not be responsible or
            liable, directly or indirectly, for any damage or loss caused or alleged
            to be caused by or in connection with use of or reliance on any such
            content, goods or services available on or through any such third party
            web sites or services.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            We strongly advise you to read the terms and conditions and privacy
            policies of any third-party web sites or services that you visit or
            interact with.
          </span>
        </p>
        <h2 className="c1" id="h.c5n7pghpgc2u">
          <span className="c0">13. Termination</span>
        </h2>
        <p className="c5">
          <span className="c4">
            We may terminate or suspend your account and bar access to the Service
            immediately, without prior notice or liability, under our sole
            discretion, for any reason whatsoever and without limitation, including
            but not limited to a breach of the Terms.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            If you wish to terminate your account, we provide a tool for it. You may
            delete your own Contexts(s) at any time from the Contexts list section.
          </span>
        </p>
        <p className="c5">
          <span>
            If you wish to delete all your stored data, please contact us via email
            at{' '}
          </span>
          <span className="c3">
            <a className="c10" href="mailto:support@easybusydo.com">
              support@easybusydo.com
            </a>
          </span>
          <span className="c4">
            . We may request you to provide proof of ownership of your account, such
            as sending the email from the email account associated with your EasyBusy
            account.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            All provisions of the Terms which by their nature should survive
            termination shall survive termination, including, without limitation,
            ownership provisions, warranty disclaimers, indemnity and limitations of
            liability.
          </span>
        </p>
        <h2 className="c1" id="h.2poe2yqxzw3q">
          <span className="c0">14. Indemnification</span>
        </h2>
        <p className="c5">
          <span className="c4">
            You agree to defend, indemnify and hold harmless EasyBusy and its
            licensee and licensors, and their employees, contractors, agents,
            officers and directors, from and against any and all claims, damages,
            obligations, losses, liabilities, costs or debt, and expenses (including
            but not limited to attorney&#39;s fees), resulting from or arising out of
            (a) your use and access of the Service, by you or any person using your
            account and password; (b) a breach of these Terms; or (c) your User
            Content provided to the Service.
          </span>
        </p>
        <h2 className="c1" id="h.k6lult2blqy2">
          <span className="c0">15. Limitations of Liability</span>
        </h2>
        <p className="c5">
          <span className="c4">
            IN NO EVENT SHALL EASYBUSY, NOR ITS DIRECTORS, EMPLOYEES, PARTNERS,
            AGENTS, SUPPLIERS, OR AFFILIATES, BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
            SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION,
            LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES,
            RESULTING FROM (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE
            THE SERVICE; (B) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE
            SERVICE; (C) ANY CONTENT OBTAINED FROM THE SERVICE; AND (D) UNAUTHORIZED
            ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT, WHETHER BASED
            ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL
            THEORY, WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH
            DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF
            ITS ESSENTIAL PURPOSE.
          </span>
        </p>
        <h2 className="c1" id="h.5dme3ar9rb4p">
          <span className="c0">16. Disclaimer</span>
        </h2>
        <p className="c5">
          <span className="c4">
            YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON
            AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. THE SERVICE IS
            PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR COURSE OF
            PERFORMANCE.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            EASYBUSY, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS DO NOT
            WARRANT THAT (A) THE SERVICE WILL FUNCTION UNINTERRUPTED, SECURE OR
            AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B) ANY ERRORS OR DEFECTS
            WILL BE CORRECTED; (C) THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS; OR (D) THE RESULTS OF USING THE SERVICE WILL MEET YOUR
            REQUIREMENTS.
          </span>
        </p>
        <h2 className="c1" id="h.1ojtlrd9gebj">
          <span className="c0">17. Exclusions</span>
        </h2>
        <p className="c5">
          <span className="c4">
            Some jurisdictions (such as the state of New Jersey) do not allow the
            exclusion of certain warranties or the exclusion or limitation of
            liability for consequential or incidental damages set forth in the
            &ldquo;LIMITATION OF LIABILITY&rdquo; and &ldquo;DISCLAIMER&rdquo;
            sections above, so the limitations above may not apply to you.
          </span>
        </p>
        <h2 className="c1" id="h.5b198koaz8sg">
          <span className="c0">18. Mobile App Terms</span>
        </h2>
        <p className="c5">
          <span className="c4">
            You are responsible for providing the mobile device, wireless service
            plan, software, Internet connections and/or other equipment or services
            that you need to download, install and use any mobile application
            versions of the Service (the &ldquo;App&rdquo;). We do not guarantee that
            the App can be accessed and used on any particular device or with any
            particular service plan. We do not guarantee that the App will be
            available in any particular geographic location. As part of the Service
            and to update you regarding the status of deliveries, you may receive
            push notifications, local client notifications, text messages, picture
            messages, alerts, emails or other types of messages directly sent to you
            in connection with the App (&ldquo;Push Messages&rdquo;). You acknowledge
            that, when you use the App, your wireless service provider may charge you
            fees for data, text messaging and/or other wireless access, including in
            connection with Push Messages. You have control over the Push Messages
            settings and can opt in or out of these Push Messages through the Service
            or through your mobile device&rsquo;s operating system (with the possible
            exception of infrequent, important service announcements and
            administrative messages). Please check with your wireless service
            provider to determine what fees apply to your access to and use of the
            App, including your receipt of Push Messages from EasyBusy. You are
            solely responsible for any fee, cost or expense that you incur to
            download, install and/or use the App on your mobile device, including for
            your receipt of push messages from EasyBusy.
          </span>
        </p>
        <p className="c5">
          <span className="c8">Mobile Software from the Apple App Store</span>
          <span className="c4">
            . The following terms and conditions apply to you only if you are using
            the App from the Apple App Store. To the extent the other terms and
            conditions of these Terms are less restrictive than, or otherwise
            conflict with, the terms and conditions of this paragraph, the more
            restrictive or conflicting terms and conditions in this paragraph apply,
            but solely with respect to the App from the Apple App Store. You
            acknowledge and agree that these Terms are solely between you and
            EasyBusy, not Apple, and that Apple has no responsibility for the App or
            content thereof. Your use of the App must comply with the App
            Store&rsquo;s applicable terms of use. You acknowledge that Apple has no
            obligation whatsoever to furnish any maintenance and support services
            with respect to the App. In the event of any failure of the App to
            conform to any applicable warranty, you may notify Apple, and Apple will
            refund the purchase price, if any, for the App to you. To the maximum
            extent permitted by applicable law, Apple will have no other warranty
            obligation whatsoever with respect to the App, and any other claims,
            losses, liabilities, damages, costs or expenses attributable to any
            failure to conform to any warranty will be solely governed by these
            Terms. You and EasyBusy acknowledge that Apple is not responsible for
            addressing any claims of yours or any third party relating to the App or
            your possession and/or use of the App, including, but not limited to: (a)
            product liability claims, (b) any claim that the App fails to conform to
            any applicable legal or regulatory requirement, and (c) claims arising
            under consumer protection or similar legislation. You and EasyBusy
            acknowledge that, in the event of any third party claim that the App or
            your possession and use of the App infringes that third party&rsquo;s
            intellectual property rights, EasyBusy, not Apple, will be solely
            responsible for the investigation, defense, settlement and discharge of
            any such intellectual property infringement claim to the extent required
            by these Terms. You must comply with applicable third-party terms of
            agreement when using the App. You and EasyBusy acknowledge and agree that
            Apple, and Apple&rsquo;s subsidiaries, are third party beneficiaries of
            these Terms as they relate to your use of the App, and that, upon your
            acceptance of these Terms, Apple will have the right (and will be deemed
            to have accepted the right) to enforce these Terms against you as a third
            party beneficiary thereof.
          </span>
        </p>
        <h2 className="c1" id="h.ufrtb7jjrp5d">
          <span className="c0">19. Governing Law</span>
        </h2>
        <p className="c5">
          <span className="c4">
            These Terms shall be governed and construed in accordance with the laws
            of California, United States, without regard to its conflict of law
            provisions or by the JAMS Rules in the case or arbitrations as outlined
            below.
          </span>
        </p>
        <h2 className="c1" id="h.jl0zhneoa35t">
          <span className="c0">20. Arbitration and Class Action Waiver</span>
        </h2>
        <p className="c5">
          <span className="c4">
            Any dispute, controversy, or claim (collectively, &ldquo;Claim&rdquo;)
            relating in any way to your use of EasyBusy&rsquo;s services and/or
            products, including the Service, will be resolved by arbitration. You and
            EasyBusy agree that any Claim will be settled by final and binding
            arbitration, using the English language, administered by JAMS under its
            Comprehensive Arbitration Rules and Procedures (the &ldquo;JAMS
            Rules&rdquo;) then in effect (those rules are deemed to be incorporated
            by reference into this section, and as of the date of these Terms).
            Arbitration will be handled by a sole arbitrator in accordance with the
            JAMS Rules. Judgment on the arbitration award may be entered in any court
            that has jurisdiction.
          </span>
        </p>
        <p className="c5">
          <span className="c8">
            Any arbitration under these Terms will take place on an individual basis
            &ndash; class arbitrations and class actions are not permitted. You
            understand that by agreeing to these Terms, you and EasyBusy are each
            waiving the right to trial by jury or to participate in a class action or
            class arbitration.
          </span>
          <span className="c4">
            &nbsp;Notwithstanding the foregoing, you and EasyBusy will have the right
            to bring an action in a court of proper jurisdiction for injunctive or
            other equitable or conservatory relief, pending a final decision by the
            arbitrator. You may instead assert your claim in &ldquo;small
            claims&rdquo; court, but only if your claim qualifies, your claim remains
            in such court and your claim remains on an individual, non-representative
            and non-class basis.
          </span>
        </p>
        <p className="c5">
          <span className="c4">
            Payment for any and all reasonable JAMS filing, administrative and
            arbitrator fees will be in accordance with the JAMS Rules.
          </span>
        </p>
        <h2 className="c1" id="h.nzwcvtd0mtts">
          <span className="c0">21. California Residents</span>
        </h2>
        <p className="c5">
          <span className="c4">
            If you are a California resident, in accordance with Cal. Civ. Code
            &sect; 1789.3, you may report complaints to the Complaint Assistance Unit
            of the Division of Consumer Services of the California Department of
            Consumer Affairs by contacting them in writing at 1625 North Market
            Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800)
            952-5210.
          </span>
        </p>
        <h2 className="c1" id="h.asc91pujbrqj">
          <span className="c0">22. Changes</span>
        </h2>
        <p className="c5">
          <span className="c4">
            We reserve the right, at our sole discretion, to modify or replace these
            Terms at any time. By continuing to access or use our Service after any
            revisions become effective, you agree to be bound by the revised terms.
            If you do not agree to the new terms, you are no longer authorized to use
            the Service.
          </span>
        </p>
        <h2 className="c1" id="h.ahszor1mhm63">
          <span className="c0">23. Waiver</span>
        </h2>
        <p className="c5">
          <span className="c4">
            Our failure to enforce any right or provision of these Terms will not be
            considered a waiver of those rights. If any provision of these Terms is
            held to be invalid or unenforceable by a court, the remaining provisions
            of these Terms will remain in effect.
          </span>
        </p>
        <h2 className="c1" id="h.99ocqbdpnxse">
          <span className="c0">24. Entire Agreement</span>
        </h2>
        <p className="c5">
          <span className="c4">
            These Terms constitute the entire agreement between us regarding our
            Service and supersede and replace any prior agreements we might have had
            between us regarding the Service (except in the case of Enterprises that
            have entered into a separate agreement with EasyBusy for their Enterprise
            Subscription).
          </span>
        </p>
        <h2 className="c1" id="h.yjtig8vh1xgd">
          <span className="c0">Contact Us</span>
        </h2>
        <p className="c5">
          <span className="c4">
            You may contact us regarding the Service or these Terms via email to
            support@easybusydo.com
          </span>
        </p>
        <p className="c5">
          <span className="c4">Last updated: April 12, 2021</span>
        </p>
        <p className="c12">
          <span className="c4" />
        </p>
      </div>
    </div>
  </Layout>
);

export default TermsPage;
